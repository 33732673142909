import React from 'react';

// libraries
import { css } from '@emotion/core';

import Head from 'components/Head';
import Callout from 'components/Callout';
import MainLayout from 'components/MainLayout';
import { button, media, Vars } from 'style';

// quick setup
import hero from 'images/sdk/hero.jpg';
import hero2x from 'images/sdk/hero@2x.jpg';

// callouts
import plugins from 'images/sdk/plugins.png';
import plugins2x from 'images/sdk/plugins@2x.png';
import pluginsBackground from 'images/sdk/pluginsBackground.png';
import netcode from 'images/sdk/netcode.png';
import netcode2x from 'images/sdk/netcode@2x.png';
import performance from 'images/sdk/performance.png';
import performance2x from 'images/sdk/performance@2x.png';
import social from 'images/sdk/social.png';
import social2x from 'images/sdk/social@2x.png';
import playeverywhere from 'images/sdk/playeverywhere.png';
import playeverywhere2x from 'images/sdk/playeverywhere@2x.png';
import spaceBackground from 'images/sdk/spaceBackground.gif';
import blueTriangle from 'images/sdk/blueTriangle.svg';
import grayTriangle from 'images/sdk/grayTriangle.svg';

// import og from 'images/sdk/og.png';

export default function SdkLanding() {
  return (
    <MainLayout hero>
      <Head
        title="Add Multiplayer And Streaming To Your Co-op Game - SDK"
        description="Integrate the Parsec game streaming technology into your game to add online multiplayer and cross platform support."
        // ogTitle={og}
      />
      <section css={heroStyle}>
        <div>
          <h1 css={title}>Game Developer SDK</h1>
          <p css={subtitle}>
            Add online multiplayer to your game and get noticed.
          </p>
        </div>
        <div>
          <a
            href="https://github.com/parsec-cloud/parsec-sdk"
            css={[
              button({ kind: 'brand', size: 'xlarge' }),
              { margin: '0 auto 1.2rem' }
            ]}
          >
            Get Started on GitHub
          </a>
          <p css={blurb}>
            No netcode, ultra low–latency multiplayer.
            <br />
            Share and play your game instantly on any device.
          </p>
        </div>
      </section>

      <section css={pluginsWrapper}>
        <Callout
          image={{ x1: plugins, x2: plugins2x }}
          subtitle="Simple Integration"
          title="Minimal. Intuitive. Straightforward."
          text="With a Unity plugin, code samples, and an active community, you’ll be able to get the Parsec SDK in your game quickly. The SDK is a single library under 5MB."
        >
          <a
            href="https://github.com/parsec-cloud/parsec-sdk/tree/master/examples"
            css={button({ kind: 'positive', size: 'large' })}
          >
            Examples on GitHub
          </a>
          <a
            href="https://discord.gg/xQaTQt2"
            css={button({ kind: 'positive', size: 'large' })}
          >
            Parsec SDK Discord
          </a>
        </Callout>
      </section>

      <section css={simpleWrapper}>
        <Callout
          image={{ x1: netcode, x2: netcode2x }}
          subtitle="No Backend"
          title="Ship your game faster without worrying about netcode or infrastructure."
          text="Writing netcode is a pain and maintaining a backend can be expensive. The Parsec SDK operates entirely peer–to–peer and hides all of that silly complexity."
        >
          <a href="/docs/sdk" css={button({ kind: 'positive', size: 'large' })}>
            Documentation
          </a>
          <a
            href="/game-streaming-technology"
            css={button({ kind: 'positive', size: 'large' })}
          >
            Our Technology
          </a>
        </Callout>
      </section>

      <section css={quickWrapper}>
        <Callout
          image={{ x1: performance, x2: performance2x }}
          subtitle="Ludicrous Speed"
          title="Zero–copy GPU accelerated video processing, custom network protocol."
          text="The Parsec app has been the leader in low–latency, peer–to–peer game streaming since 2016 — now your game can leverage the same code used by millions."
        >
          <a
            href="/game-streaming-technology"
            css={button({ kind: 'positive', size: 'large' })}
          >
            Our Technology
          </a>
        </Callout>
      </section>

      <section css={spaceWrapper}>
        <Callout
          image={{ x1: social, x2: social2x }}
          subtitle="Get Social"
          title="We’re building the world's largest arcade."
          text="Allow link sharing in seconds from within your game, or wheel your game into the Parsec Arcade for public matchmaking. Friends can be playing together instantly — even from a web browser."
        >
          <a href="/signup" css={button({ kind: 'positive', size: 'large' })}>
            Check Out the Arcade
          </a>
        </Callout>
        <Callout
          image={{ x1: playeverywhere, x2: playeverywhere2x }}
          subtitle="Cross Platform"
          title="Reach more gamers by supporting more platforms."
          text="Host from Windows or macOS and stream everywhere, incuding Linux, iOS, Android, and Chrome — even a humble Raspberry Pi!"
        >
          <a
            href="https://github.com/parsec-cloud/parsec-sdk/tree/master/sdk"
            css={button({ kind: 'positive', size: 'large' })}
          >
            SDK on GitHub
          </a>
        </Callout>
      </section>
      <div css={getStartedWrapper}>
        <h3 css={getStartedTitle}>The time is now.</h3>
        <p>
          Browse the Parsec SDK repo for code samples, documentation, and
          discussion.
        </p>
        <a
          href="https://github.com/parsec-cloud/parsec-sdk"
          css={getStartedButton}
        >
          Get Started on GitHub
        </a>
      </div>
    </MainLayout>
  );
}

const heroStyle = css`
  background: #130a1f center url(${hero}) no-repeat;
  background-size: 57.6rem;
  text-align: center;
  height: 100vh;
  min-height: 60rem;
  padding: 6rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.hidpi} {
    background-image: url(${hero2x});
  }

  &:before {
    content: '';
    display: block;
  }

  @media screen and (min-width: 480px) {
    background-size: 120%;
  }

  @media screen and (min-width: 800px) {
    background-size: 141rem;
  }
`;

const title = css`
  font-size: ${Vars.FontSizeTitle};
  line-height: ${Vars.LineHeightTitle};
  text-shadow: 0 0.2rem 0.4rem ${Vars.Shade600};
`;

const subtitle = css`
  font-size: ${Vars.FontSizeH4};
  line-height: ${Vars.LineHeightH4};
  text-shadow: 0 0.2rem 0.4rem ${Vars.Shade600};
  font-weight: 700;
  margin-bottom: 3rem;
`;

const blurb = css`
  margin-top: 1rem;
  font-weight: bold;
  font-size: ${Vars.FontSizeH3};
  line-height: ${Vars.LineHeightH3};
  max-width: 60rem;
  margin: 1rem auto 0 auto;
`;

const pluginsWrapper = css`
  background: url(${pluginsBackground}) center left repeat-x,
    linear-gradient(#130a1f 40%, #00174a 40%);
  background-size: 4px 236px, auto;
`;

const simpleWrapper = css`
  background-color: #00174a;
  min-height: 640px;
  display: flex;
`;

const quickWrapper = css`
  background-color: #00174a;
  background-size: 4px 236px, auto;
  min-height: 640px;
  display: flex;
`;

const spaceWrapper = css`
  position: relative;
  background: url(${spaceBackground});
  background-size: cover;
  min-height: 64rem;
  padding: 8rem 0 16rem;
  display: flex;
  flex-direction: column;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 16px;
  }

  &::before {
    top: 0;
    background: url(${blueTriangle}) top left repeat-x;
  }

  &::after {
    bottom: 0;
    background: url(${grayTriangle}) top left repeat-x;
  }
`;

const getStartedWrapper = css`
  background-color: #2c2c2e;
  text-align: center;
  padding: 4rem;
`;

const getStartedTitle = css`
  position: relative;
  margin: 0 0 4rem;
  font-weight: 900;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 0.2rem;
    width: 10rem;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 2rem;
    background-color: ${Vars.PositiveColor};
  }
`;

const getStartedButton = css`
  --border-color: ${Vars.BrandPrimaryColor};
  --border-color-hover: ${Vars.NegativeHoverColor};
  color: ${Vars.BrandPrimaryColor};
  ${button({ size: 'large' })};
`;
